import { Box, Button, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

import { ContributorWorkSteps } from '../../../../types/contributorWork';
import { useStyles } from './styled';

export type Props = {
    name?: string;
    workInProgressOnClick?: () => void;
    questionsOnClick?: () => void;
    currentStep: ContributorWorkSteps;
    workToken: string | null;
};

const Header: FunctionComponent<Props> = ({
    name,
    workInProgressOnClick,
    questionsOnClick,
    currentStep,
    workToken
}: Props) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>{name}</Typography>
                {workToken && (
                    <Button 
                        href={`vscode://beehive.beehive-contribute?jwt=${workToken}`}
                        className="launch-extension-btn"
                    >
                        <img src="vscode.svg" alt="Extension" />
                        <Typography className={classes.launchExtensionText}>
                            Launch Extension
                        </Typography>
                    </Button>
                )}
                {(currentStep === ContributorWorkSteps.AnalyzePullRequest) && (
                    <Button
                        className="work-in-progress-btn"
                        onClick={workInProgressOnClick}
                    >
                        <img src="clock.svg" />
                        <Typography className={classes.workInProgressText}>
                            Work in progress
                        </Typography>
                        <Typography className={classes.cancelText}>Cancel</Typography>
                    </Button>
                )}
                {currentStep === ContributorWorkSteps.AnalyzePullRequest && (
                    <Button className="questions-btn" onClick={questionsOnClick}>
                        <img src="chat.svg" alt="Chat" />
                        <Typography className={classes.questionsText}>
                            Questions about this work?
                        </Typography>
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Header;
